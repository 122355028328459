<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row class="products-row">
      <v-col
        sm="10"
        offset-sm="1"
        md="6"
        offset-md="3">
        <v-row>
          <v-col cols="12">
            <vue-tel-input-vuetify
              v-model="form.tel"
              label="เบอร์โทรศัพท์"
              default-country="th"
              dense
              outlined
              @country-changed="countryChange($event)" />
            <!-- <tel-input
              label="เบอร์โทรศัพท์"
              class="mt-1 phone-sel birth-date-picker"
              @telInfo="setTelInfo" /> -->
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.firstName"
              label="ชื่อ"
              dense
              hide-details
              outlined />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.lastName"
              label="นามสกุล"
              dense
              hide-details
              outlined />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model.trim="form.email"
              label="Email"
              dense
              hide-details
              outlined />
          </v-col>
          <v-col cols="12">
            <v-menu
              ref="menu"
              v-model="birthDateMenu"
              :close-on-content-click="false"
              :return-value.sync="form.birthDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="birthDateCompute"
                  label="วันเกิด"
                  dense
                  hide-details
                  outlined
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <v-date-picker
                v-model="form.birthDate"
                :max="$dayjs(new Date()).format()"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="birthDateMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(form.birthDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="form.gender"
              :items="genders"
              label="เพศ"
              dense
              hide-details
              outlined />
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="form.type"
              label="ประเภทสมาชิก"
              row>
              <v-radio
                label="VVIP"
                value="vvip" />
              <v-radio
                label="VIP"
                value="vip" />
              <v-radio
                label="Member"
                value="normal" />
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            class="text-center">
            <v-btn
              color="primary"
              @click="submit()">
              เพิ่มสมาชิก
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import MemberProvider from '@/resources/MemberProvider'
// import TelInput from '../components/TelInput.vue'

const MemberService = new MemberProvider()

export default {
  components: {
    // TelInput
  },
  data () {
    return {
      birthDateMenu: false,
      genders: [
        { text: 'หญิง', value: 'female' },
        { text: 'ชาย', value: 'male' },
        { text: 'ไม่ระบุ', value: 'N/A' }
      ],
      form: {
        firstName: null,
        lastName: null,
        birthDate: null,
        email: null,
        tel: null,
        telInfo: null,
        type: 'normal',
        gender: null,
        createdIn: 'manual_storefront'
      }
    }
  },
  computed: {
    birthDateCompute () {
      return this.form.birthDate ? this.$dayjs(this.form.birthDate).format('DD MMM YYYY') : null
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    countryChange (telInfo) {
      this.form.telInfo = telInfo
    },
    setTelInfo (tel, telInfo) {
      this.form.tel = this.setThTel(tel, telInfo)
      this.form.telInfo = telInfo
    },
    setThTel (tel, telInfo) {
      let result = tel
      if (telInfo.dialCode === '66' && tel.length === 10 && tel[0] === '0') {
        result = `+${telInfo.dialCode}${tel.slice(1)}`
      } else if (telInfo.dialCode === '66' && tel.length === 9 && tel[0] !== '0') {
        result = `+${telInfo.dialCode}${tel}`
      }

      return result
    },
    validate (form) {
      let validated = { status: true }
      if (form.tel?.length !== 12 && form.telInfo?.dialCode === '66') {
        validated = { status: false, message: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง' }
      } else if (!form.firstName || !form.lastName) {
        validated = { status: false, message: 'กรุณากรอกชื่อและนามสกุลให้ถูกต้อง' }
      } else if (!form.birthDate) {
        validated = { status: false, message: 'กรุณาเลือกวันเกิด' }
      } else if (!form.gender) {
        validated = { status: false, message: 'กรุณาเลือกเพศ' }
      }

      return validated
    },
    submit () {
      const validated = this.validate(this.form)
      if (validated.status) {
        this.createMember(this.form)
      } else {
        this.setSnackbar({
          value: true,
          message: validated.message,
          type: 'error'
        })
      }
    },
    async createMember (member) {
      let created = null
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'CREATING MEMBER...'
        })

        created = await MemberService.createMember({
          ...member,
          tel: member.tel.trim().replaceAll(' ', '').replaceAll('-', '')
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[CREATE-MEMBER-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })

        if (created.message === 'done') {
          this.setSnackbar({
            value: true,
            message: 'สร้างสมาชิกสำเร็จ',
            type: 'success'
          })

          this.$router.push({ name: 'MemberManagement' })
        }
      }
    }
  }
}
</script>
